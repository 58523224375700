import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container className="singleCol">
      <h1 className="text-center my-4">Tickets</h1> 
      <p className="text-center">
        <a href="https://www.simpletix.com/e/ralph-mark-gilbert-civil-rights-museum-of-tickets-123039" className="btn btn-primary"><strong>Purchase Tickets Online</strong></a>
      </p>   
       
      <div className="d-md-flex justify-content-evenly mt-4">
        <div>
          <h2>Individual Tickets</h2>
          <ul className="list-unstyled lead">
            <li>Adult $14</li>
            <li>Seniors $8</li>
            <li>Students $7</li>
          </ul>
        </div>
        <div>
          <h2>Group Tickets (10 or more)</h2>
          <ul className="list-unstyled lead">
            <li>Adult $10</li>
            <li>Seniors $7</li>
            <li>Students $5</li>
            {/* <li>Educators with students $5</li> */}
          </ul>
          {/* <p><small>A minimum of 10 tickets or more is required</small></p> */}
        </div>
      </div>

      {/* <hr />
      <div className="py-3 pt-4">
        <h2 className="text-center">Limited group availability on Tuesday & Wednesday for groups of 25 or more</h2>
        <p>
          The RMG Civil Rights Museum has limited availability on Tuesday & Wednesday for large groups of 25 or more people. To arrange a time for these days, please <Link to="/contact/">contact</Link> Vaughnette Goode-Walker, Executive Director, at least two weeks prior to your requested day. Please note we can't guarantee availability due to limited hours. We will do the best to fulfill your request.
        </p>
      </div> */}

      <hr />
      
      <div className="py-3 pt-4">

      </div>
      <h2 className="text-center">A  Family Reunion Summer at the Ralph Mark Gilbert Civil Rights Museum</h2>
      <p>
      July and August were busy months, this year, as the Museum welcomed Family Reunions. We met and led tours for members of the Queens Family, the Lowe Family, the King Matthew Brown Family and the Clayton and Della Scruggs Reunion. The highlight of the visit was to hear the eldest family member share the Family history and the youngest looking toward the future while learning about the past. 
      </p>
      <p>Think about bringing your Family Reunion next year! </p>
      <Row className="g-5 mb-4">
        <Col xs={6}>
          <StaticImage
            src="../images/family-renunion-vert-01.jpg"
            quality={95}
            width={450}
            formats={["auto", "webp", "avif"]}
            alt="Family Renunion"
          />
        </Col>
        <Col xs={6}>
          <StaticImage
            src="../images/family-renunion-vert-02.jpg"
            quality={95}
            width={450}
            formats={["auto", "webp", "avif"]}
            alt="Family Renunion"
          />
        </Col>
      </Row>
      <p>
        <StaticImage
          src="../images/family-renunion-horizontal.jpg"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Family Renunion"
        />
      </p>
    </Container>      
  </Layout>
)

export const Head = () => <Seo title="Purchase Tickets to the Museum" />

export default SecondPage
